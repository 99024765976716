.aboutContainer {
  padding: 1rem;
  margin: 1rem;
  background: #393939;
  box-shadow: 10px 10px 10px #00cf94;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 2rem;
  line-height: 1.7;
}
