.socialCard {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto;
  background: #393939;
  height: 15rem;
  width: 100%;
  background: none;
}
.socialItem {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.socialItem:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 10px 5px #00cf94;
  .socialSVG {
    transform: scale(1.5);
  }
}
.socialSVG {
  height: 4rem;
  width: 4rem;
}

#twitter {
  background: #1da1f2;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}
#github {
  background: #181717;
}
#youtube {
  background: #ff0000;
}
#linkedin {
  background: #0077b5;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
@media screen and (max-width: 800px) {
  .socialCard {
    grid-template-columns: auto auto;
  }
  .socialSVG {
    height: 2rem;
    width: 2rem;
  }
  #twitter,
  #linkedin {
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  #github {
    border-top-right-radius: 2rem;
  }
  #youtube {
    border-bottom-left-radius: 2rem;
  }
}
