.logo {
  font-family: dimitri;
  font-size: 3rem;
  text-shadow: 0px 3px 3px #00cf94;
  color: #ebebeb;
}
.logo:hover {
  text-shadow: 0px 3px 3px #ebebeb;
  color: #00cf94;
}
.logoContainer {
  padding-top: 2rem;
  padding-left: 2rem;
}
a {
  text-decoration: none;
}
h1 {
  margin: 0;
}
@media screen and (max-width: 800px) {
  .logoContainer {
    padding-left: 0rem;
  }
  .logo {
    font-size: 3rem;
  }
  h1 {
    text-align: center;
  }
}
